import React from "react";
import plugins from '../../content/pluginsInfo'
import Layout from "../components/layout"

export default class HeaderPoster extends React.Component{
    componentWillMount() {
        // window.scrollTo(0,0)
    }

    render() {
        const plugin=plugins[0]
        const styleImgs=plugin.styleImgs
        const demos=plugin.useInDemos.map((demo,index)=>
          <li className="animate-up" key={index}>
              <a href={demo.path}> <img className="border"  src={demo.poster}  alt="示意图" />
                  <div>{demo.name}</div></a>
          </li>
        )
        return(
          <Layout>
            <div>
                <section className="section light-green cutoff">
                    <div className="pagewidth">
                        <h1>页头图</h1>
                        <h2>蒙版海报，位于页面顶部，菜单栏上方，展示赛事特色，丰富网站界面层次。</h2>
                    </div>
                </section>

                <section className="section white cutoff">
                    <div className="pagewidth">
                        <h1>多种样式</h1>
                        <ul className="grid2 slide-up animated">
                            <li className="animate-up" >
                                <a href="#"> <img className="border"  src={styleImgs[0]}  alt="示意图" /></a>

                            </li>
                            <li className="animate-up" >
                                <a href="#"> <img className="border"  src={styleImgs[1]}  alt="示意图" /></a>

                            </li>
                        </ul>
                    </div>
                </section>

                <section className="section light-gray cutoff">
                    <div className="pagewidth">
                        <h1>适用模板</h1>
                        <ul className="grid3 slide-up animated">
                            {demos}
                        </ul>
                    </div>
                </section>

            </div>
          </Layout>
        )
    }
}